import { PeopleOutline } from '@mui/icons-material'
import { isGlobalworth, paths } from 'config'
import { TFunction } from 'react-i18next'
import { Profiles } from 'shared/assets'
import { Loadable } from 'shared/components'
import { SourceRoute } from 'shared/hooks/use-dynamic-back'
import { ModuleRoute } from 'shared/types'

const routes: ModuleRoute[] = [
  {
    path: paths.profiles,
    admin: true,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Profiles : PeopleOutline,
      label: 'nav.profiles',
    },
    component: Loadable({
      component: () => import('./pages/Profiles'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.accessCode,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/AccessCode'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.profilesList',
          href: paths.profiles,
        },
        dynamicBackToTitle: (
          name: string,
          source: SourceRoute | undefined,
          t: TFunction<'translation', undefined>
        ) => `${t('nav.goBack.profile')}: ${name}`,
      },
    },
  },
  {
    path: paths.addProfile,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/AddProfile'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.profilesList',
          href: paths.profiles,
        },
      },
    },
  },
  {
    path: paths.profile,
    admin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/Profile'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.profilesList',
          href: paths.profiles,
        },
      },
    },
  },
]

export default routes
