import { useTranslation } from 'react-i18next'
import { Option, ExtendedOption, User } from 'shared/types'

export const useMapUserToOption = () => {
  const { t } = useTranslation()
  const mapUserToOption = ({
    email: title,
    id: value,
    name: subtitle,
  }: User): Option<string, string> => {
    if (!title && !subtitle)
      return {
        title: t('anonymousUser'),
        value,
      }
    return {
      title: title || subtitle || '',
      subtitle: subtitle || undefined,
      value,
    }
  }

  return mapUserToOption
}

interface UserWithDetails extends User {
  disabled?: boolean
}

export const useMapUserToExtendedOption = () => {
  const { t } = useTranslation()
  const mapUserToOption = ({
    email: title,
    id: value,
    name: subtitle,
    disabled,
  }: UserWithDetails): ExtendedOption<string, string> => {
    if (!title && !subtitle)
      return {
        title: t('anonymousUser'),
        value,
      }
    return {
      title: title || subtitle || '',
      subtitle: subtitle || undefined,
      value,
      disabled,
    }
  }

  return mapUserToOption
}

interface UserWithHint extends User {
  hint?: string
}

export const useMapUserToOptionWithHint = () => {
  const { t } = useTranslation()
  const mapUserToOption = ({
    email: title,
    id: value,
    name: subtitle,
    hint,
  }: UserWithHint): ExtendedOption<React.ReactNode, string> => {
    const buildSubtitle = (): string | undefined | React.ReactNode => {
      if (subtitle && hint) return <>{hint} • {subtitle}</>;
      if (subtitle) return subtitle
      if (hint) return hint
      return undefined
    }

    const currentSubtitle = buildSubtitle()

    if (!title && !subtitle)
      return {
        title: t('anonymousUser'),
        subtitle: currentSubtitle,
        value,
      }

    return {
      title: title || currentSubtitle || '',
      subtitle: currentSubtitle || undefined,
      value,
    }
  }

  return mapUserToOption
}
