import { ModuleRoute } from 'shared/types'

import home from './home'
import users from './users'
import profiles from './profiles'
import zones from './zones'
import history from './history'
import booking from './booking'
import resourceProfiles from './resourceProfiles'
import resources from './resources'
import building from './building'
import tutorial from './tutorial'
import newsfeed from './newsfeed'
import changePassword from './changePassword'
import bookingConfirmation from './bookingConfirmation'
import deleteAccount from './deleteAccount'
import organizations from './organizations'
import userPreferences from './userPreferences'

const routes: ModuleRoute[] = [
  // Add routes below
  ...booking,
  ...home,
  ...newsfeed,
  ...users,
  ...profiles,
  ...resources,
  ...resourceProfiles,
  ...zones,
  ...organizations,
  ...history,
  ...building,
  ...tutorial,
  ...changePassword,
  ...bookingConfirmation,
  ...deleteAccount,
  ...userPreferences,
]

export default routes
