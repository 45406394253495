import i18next, { InitOptions } from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { env } from 'config'

export const DEFAULT_LANGUAGE = 'en'
export const DEFAULT_NAMESPACE = 'common'

interface Language {
  code: string
  name: string
  icon?: string
}

const isGlobalworth = env.productFlavor === 'globalworth'

export const languages: Language[] = [
  {
    code: 'en',
    name: 'English',
    icon: 'gb',
  },
  {
    code: 'pl',
    name: 'Polski',
    icon: 'pl',
  },
  ...(isGlobalworth
    ? [
        {
          code: 'ro',
          name: 'Română',
          icon: 'ro',
        },
      ]
    : []),
]
const i18nOptions: InitOptions = {
  fallbackLng: DEFAULT_LANGUAGE,
  detection: {
    order: ['cookie', 'navigator'],
    caches: ['cookie'],
  },
  defaultNS: DEFAULT_NAMESPACE,
  fallbackNS: DEFAULT_NAMESPACE,
  backend: {
    loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    queryStringParams: {
      v: process.env.REACT_APP_VERSION || Date.now().toString(),
    },
  },
}

const i18n = {
  init: () =>
    i18next
      .use(Backend)
      .use(initReactI18next)
      .use(LanguageDetector)
      .init(i18nOptions),
}

export default i18n
