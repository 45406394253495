import { useQueryParams } from './use-query-params'

const PREV_URL_QUERY_PARAM = 'prevUrl'
const PREV_NAME_QUERY_PARAM = 'prevName'
const PREV_SOURCE_QUERY_PARAM = 'prevSource'

export enum SourceRoute {
  AccessCode = 'AccessCode',
}

const getCurrentUrlPartsExceptDomain = (): string => {
  const { pathname, search, hash } = window.location
  return `${pathname}${search}${hash}`
}

export const useDynamicBack = () => {
  const getParam = useQueryParams()

  let data: {
    url: string
    name: string
    source: SourceRoute | undefined
  } | null = null

  const prevUrlValue = getParam(PREV_URL_QUERY_PARAM)
  const prevNameValue = getParam(PREV_NAME_QUERY_PARAM)
  const prevSourceValue = getParam(PREV_SOURCE_QUERY_PARAM)

  if (prevUrlValue && prevNameValue)
    data = {
      url: prevUrlValue,
      name: prevNameValue,
      source:
        prevSourceValue.length === 0
          ? undefined
          : (prevSourceValue as SourceRoute),
    }

  const getQuery = (name: string, sourceRoute?: SourceRoute): string => {
    const currentQuery = `${PREV_URL_QUERY_PARAM}=${encodeURIComponent(
      getCurrentUrlPartsExceptDomain()
    )}&${PREV_NAME_QUERY_PARAM}=${name}`
    return sourceRoute
      ? `${currentQuery}&${PREV_SOURCE_QUERY_PARAM}=${sourceRoute}`
      : currentQuery
  }

  return { data, getQuery }
}
