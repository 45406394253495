const paths = <const>{
  home: '/',
  confirmBooking: '/confirm-booking',
  signIn: '/sign-in',
  signInWithLink: '/sign-in-with-link',
  activateAccount: '/activate-account',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  changePassword: '/change-password',
  users: '/users',
  addUser: '/users/add',
  user: '/users/:id',
  profiles: '/profiles',
  addProfile: '/profiles/add',
  accessCode: '/profiles/access-code/:id',
  profile: '/profiles/:id',
  resourceProfiles: '/resource-profiles',
  addResourceProfile: '/resource-profiles/add',
  resourceProfile: '/resource-profiles/:id',
  resources: '/resources',
  addResource: '/resources/add',
  editResource: '/resources/edit/:id',
  resource: '/resources/:id',
  zones: '/zones',
  addZone: '/zones/add',
  zone: '/zones/:id',
  parkingSpots: '/booking/parking-spots',
  history: '/history',
  building: '/building/:id',
  buildings: '/buildings',
  tutorial: '/tutorial',
  newsfeed: '/newsfeed',
  addNewsfeedNotification: '/newsfeed/add',
  deleteAccount: '/delete-account',
  organizations: '/organizations',
  organization: '/organizations/:id',
  editLock: '/organizations/edit-lock/:id',
  consents: '/consents',
}

export default paths
