import { isGlobalworth, paths } from 'config'
import { Zones } from 'shared/assets'
import { Loadable } from 'shared/components'
import { ModuleRoute } from 'shared/types'
import CorporateFareOutlinedIcon from '@mui/icons-material/CorporateFareOutlined'
import { TFunction } from 'react-i18next'
import { SourceRoute } from 'shared/hooks/use-dynamic-back'

const routes: ModuleRoute[] = [
  {
    path: paths.organizations,
    admin: true,
    organizationAdmin: true,
    exact: true,
    linkSettings: {
      icon: isGlobalworth ? Zones : CorporateFareOutlinedIcon, //ToDo: GW icon
      label: 'nav.organizations',
    },
    component: Loadable({
      component: () => import('./pages/Organizations'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.dashboard',
          href: paths.home,
        },
      },
    },
  },
  {
    path: paths.organization,
    admin: true,
    organizationAdmin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/Organization'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.organizationsList',
          href: paths.organizations,
        },
      },
    },
  },
  {
    path: paths.editLock,
    admin: true,
    organizationAdmin: true,
    exact: true,
    component: Loadable({
      component: () => import('./pages/EditLock/EditLock'),
    }),
    layoutSettings: {
      topbarProps: {
        backTo: {
          title: 'nav.goBack.organizationsList',
          href: paths.organizations,
        },
        dynamicBackToTitle: (
          name: string,
          source: SourceRoute | undefined,
          t: TFunction<'translation', undefined>
        ) => `${t('nav.goBack.organization')}: ${name}`,
      },
    },
  },
]

export default routes
